'use client';
import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation, Trans } from 'next-i18next';
import { useSessionUser } from '@/hooks/useSessionUser';
import Loading from '@/components/Loading/Loading';
import Link from 'next/link';
import { useAudio } from '@/context/AudioContext';
import SettingModal from '@/components/_modals/SettingModal';
import { Tooltip } from 'react-tooltip';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useAuth } from '@/context/AuthContext';
import InitAppModal from '@/components/_modals/InitAppModal';
import EdgeButton from '@/components/_buttons/EdgeButton';
import Image from 'next/image';
import styled from 'styled-components';
import useAskCount from '@/hooks/stores/useAskCountStore';
import { SupportedLanguage } from '@/settings/endpoint';
import PremiumModal from '@/components/_modals/PremiumModal';

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

const imageClassName = {
  jp: 'w-[300px] h-[300px] md:max-w-[500px] md:h-[300px] s:w-[200px] s:h-[210px] xs:w-[220px] xs:h-[230px] ml-8',
  us: 'w-[500px] h-[300px] md:w-[500px] md:h-[300px] s:w-[380px] s:h-[220px] xs:w-[380px] xs:h-[220px]',
};

export default function Page() {
  const { user, login } = useSessionUser();
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const { isPremium, steamUser, setPremium } = useAskCount();
  const { noUserName, token, handleSetUser } = useAuth();
  const [isFirstLaunch, setIsFirstLaunch] = React.useState(true);
  const { playSoundEffect } = useAudio();
  const [isDisplaySettingModal, setDisplaySettingModal] = React.useState(false);
  const [isDisplayPremiumModal, setDisplayPremiumModal] = React.useState(false);
  const [displayRoomMenu, setDisplayRoomMenu] = React.useState(false);

  const handleCloseLaunchModal = () => {
    setIsFirstLaunch(false);
    localStorage.setItem('launched', 'true');
  };

  const handleCloseRoomMenu = () => {
    if (!displayRoomMenu) return;
    setDisplayRoomMenu(false);
  };

  const createGuestUser = async (data: { userName: string }) => {
    if (!token) return;
    try {
      await login(data.userName, token, i18n.language as SupportedLanguage);
      handleSetUser();
    } catch (error) {
      console.log(error);
    }
  };

  const startPayment = () => {
    // Flutterアプリを起動（カスタムURLスキームを使用）
    window.location.href = 'deduceTogether://start-payment';
  };

  // 決済完了時の処理
  const paymentCompleted = () => {
    alert(t('common.paymentComplete'));
  };

  // 広告見終わった時の処理
  const adWatchComplete = () => {
    alert(t('common.adWatchComplete'));
  };

  const handleShowSettingModal = React.useCallback(() => {
    setDisplaySettingModal(true);
  }, []);

  const handleCloseSettingModal = React.useCallback(() => {
    setDisplaySettingModal(false);
  }, []);

  const handleShowPremiumModal = React.useCallback(() => {
    setDisplayPremiumModal(true);
  }, []);

  const handleClosePremiumModal = React.useCallback(() => {
    setDisplayPremiumModal(false);
  }, []);

  React.useEffect(() => {
    if (noUserName) {
      createGuestUser({ userName: t('common.defaultUsername') });
      return;
    }
    setIsFirstLaunch(!localStorage.getItem('launched'));
    window.onPremiumStatusChanged = (isPremium: boolean) => {
      // 課金成功時の処理
      if (isPremium) {
        setPremium(true);
      } else {
        setPremium(false);
      }
    };
  }, [noUserName, i18n.language]);

  React.useEffect(() => {
    if (router.query.name) {
      localStorage.setItem('userName', String(router.query.name));
      localStorage.setItem('launched', 'true');
      setIsFirstLaunch(false);
    }
  }, [router.query.name]);

  return (
    <>
      {user ? (
        <>
          {isFirstLaunch ? (
            <InitAppModal onClose={handleCloseLaunchModal} />
          ) : (
            <div className="relative h-full flex flex-col justify-start items-center pt-20 gap-16 galaxy-h:gap-4">
              <div className="relative flex justify-center">
                <img
                  className={`${imageClassName[i18n.language as 'jp' | 'us']}`}
                  src={`/images/title_logo_${i18n.language}1.png`}
                  alt="logo"
                />
              </div>

              <div className="flex flex-col gap-6 text-gold-300 mb-10">
                {/* <Link href={'/single-mode'}>
                  <EdgeButton className="!w-[168px] h-[56px] text-base bg-contain">
                    シングルプレイ
                  </EdgeButton>
                </Link> */}

                <Link href={'/lobbies'}>
                  <div className="relative flex flex-col items-center">
                    <div className="text-2xl">{t('menu.playGame')}</div>
                    <div
                      className='select-none bg-[url("/images/menu_line.png")] w-[220px] h-4 bg-no-repeat  absolute -bottom-4'
                      style={{ backgroundSize: '100% 100%' }}
                    />
                  </div>
                </Link>
                <Link href={'/original-questions'}>
                  <div className="relative flex flex-col items-center">
                    <div className="text-2xl">
                      {t('menu.originalQuestions')}
                    </div>
                    <div
                      className='select-none bg-[url("/images/menu_line.png")] w-[220px] h-4 bg-no-repeat  absolute -bottom-4'
                      style={{ backgroundSize: '100% 100%' }}
                    />
                  </div>
                </Link>
                <div
                  className="relative flex flex-col items-center"
                  onClick={handleShowSettingModal}
                >
                  <div className="text-2xl">{t('menu.options')}</div>
                  <div
                    className='select-none bg-[url("/images/menu_line.png")] w-[220px] h-4 bg-no-repeat  absolute -bottom-4'
                    style={{ backgroundSize: '100% 100%' }}
                  />
                </div>
                {!steamUser && (
                  <div
                    className="relative flex flex-col items-center"
                    onClick={handleShowPremiumModal}
                  >
                    <div className="text-2xl">{t('menu.premium')}</div>
                    <div
                      className='select-none bg-[url("/images/menu_line.png")] w-[220px] h-4 bg-no-repeat  absolute -bottom-4'
                      style={{ backgroundSize: '100% 100%' }}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col items-start gap-4 fixed bottom-6">
                <a
                  href={t('discordLink')}
                  className="room-icon flex hover-scale-1_3 items-center gap-2 justify-end text-base  text-brown-secondary font-bold cursor-pointer active-scale-90"
                  style={{ letterSpacing: '2px' }}
                >
                  <Image
                    src="/images/icon_discord.svg"
                    width={32}
                    height={32}
                    alt="to home"
                  />
                  {t('discord')}{' '}
                </a>
              </div>

              {isDisplaySettingModal && (
                <SettingModal onClose={handleCloseSettingModal} />
              )}
              {isDisplayPremiumModal && (
                <PremiumModal onClose={handleClosePremiumModal} />
              )}
              <Tooltip id="my-tooltip" className="z-10" />
            </div>
          )}
        </>
      ) : (
        <>
          <Loading title="Loading" size={8} />
        </>
      )}
    </>
  );
}

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'], null, [
        'us',
        'jp',
        'kr',
      ])),
    },
  };
}
