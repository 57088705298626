import * as React from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useModalOrders } from '@/hooks/useModalOrders';
import { Kaisei_Opti } from 'next/font/google';

const kaisei = Kaisei_Opti({ weight: '400', subsets: ['latin'] });

interface Props {
  width?: number;
  children: React.ReactNode;
  hideCloseButton?: boolean;
  onClose?: () => void;
}

export interface ForwardRefHandlers {
  close: () => void;
}

const BackdropFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const BackdropFadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const ModalFadeIn = keyframes`
  from {
    transform: scale(1.4);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const ModalFadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.4);
    opacity: 0;
  }
`;

const Backdrop = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${BackdropFadeOut} 0.3s ease-in-out;
  &.active {
    animation: ${BackdropFadeIn} 0.6s ease-in-out;
    opacity: 1;
  }
`;

const ModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: #000;
  transform-origin: center;
  transform: scale(1);
  opacity: 0;
  animation: ${ModalFadeOut} 0.4s ease-in-out;
  overflow-y: hidden;
  overflow-x: hidden;

  &.active {
    animation: ${ModalFadeIn} 0.3s ease-in-out;
    opacity: 1;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    background-clip: padding-box;
  }
`;

export const FullScreenModalLayout = React.forwardRef<
  ForwardRefHandlers,
  Props
>(({ children, hideCloseButton = false, onClose, width = 480 }, forwardRef) => {
  const [isDisplay, setIsDisplay] = React.useState(true);
  const [modalId, setModalId] = React.useState(uuidv4());
  const [modals, addModal, removeModal, isForeground] = useModalOrders();

  const handleClose = () => {
    setIsDisplay(false);
  };

  const handleEscape = React.useCallback(
    (event: KeyboardEvent) => {
      if (!isForeground(modalId)) {
        return;
      }
      if (['Escape', 'Esc'].includes(event.key)) {
        event.preventDefault();
        setIsDisplay(false);
      }
    },
    [modalId, isForeground],
  );

  React.useImperativeHandle(forwardRef, () => {
    return {
      close: () => {
        setIsDisplay(false);
      },
    };
  });

  React.useEffect(() => {
    if (!isDisplay && typeof onClose === 'function') {
      setTimeout(() => {
        removeModal(modalId);
        onClose();
      }, 400);
    }
  }, [modalId, isDisplay, onClose, removeModal]);

  React.useEffect(() => {
    document.addEventListener('keydown', handleEscape, false);
    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [handleEscape]);

  React.useEffect(() => {
    if (!modalId) return;
    addModal(modalId);
  }, [modalId]);

  return createPortal(
    <ModalWrapper className={`${kaisei.className}`}>
      <Backdrop onClick={handleClose} className={isDisplay ? 'active' : ''} />
      <Modal className={isDisplay ? 'active' : ''}>
        {onClose && !hideCloseButton && (
          <button
            className="absolute top-6 right-6 z-50 bg-[url('/images/button_close.png')] h-[44px] w-[44px] bg-cover bg-center bg-no-repeat"

            onClick={handleClose}
          />
        )}
        {children}
      </Modal>
    </ModalWrapper>,
    document.body,
  );
});
FullScreenModalLayout.displayName = 'FullScreenModalLayout';
