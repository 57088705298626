import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { ForwardRefHandlers, ModalLayout } from '../ModalLayout';
import EdgeButton from '@/components/_buttons/EdgeButton';
import useAskCount from '@/hooks/stores/useAskCountStore';
import { toast } from 'react-toastify';

interface Props {
  onClose: () => void;
}

export default function PremiumModal({ onClose }: Props) {
  const ref = React.useRef<ForwardRefHandlers>(null);
  const { setPremium } = useAskCount();
  const { t } = useTranslation();

  const handleClose = React.useCallback(() => {
    ref.current?.close();
  }, []);

  const handlePurchase = React.useCallback(() => {
    // @ts-ignore
    window.flutter_inappwebview?.callHandler('showPaymentDialog', '');
  }, []);

  const handleRestore = React.useCallback(() => {
    // @ts-ignore
    window.flutter_inappwebview?.callHandler('restorePurchase', '');
  }, []);

  React.useEffect(() => {
    window.onPaymentSuccess = () => {
      toast.success(t('success.purchase'));
      setPremium(true);
      handleClose();
    };

    window.onPaymentError = (error: string) => {
      toast.error(t('errors.purchaseFailed'));
    };

    return () => {
      window.onPaymentSuccess = undefined;
      window.onPaymentError = undefined;
    };
  }, []);

  return (
    <ModalLayout ref={ref} onClose={onClose} className="!h-auto">
      <div className="p-4 flex flex-col gap-4 items-center justify-center h-full text-white">
        <div className="text-2xl">{t('modal.premium.title')}</div>
        <div>{t('modal.premium.description')}</div>

        <div className="flex flex-col gap-4 w-full items-center">
          <EdgeButton
            className="!w-[240px] h-[56px] text-base bg-contain"
            onClick={handlePurchase}
          >
            {t('modal.premium.buy')}
          </EdgeButton>
          <div className="text-sm text-gray-300">
            {t('modal.premium.restoreDescription')}
          </div>
          <EdgeButton
            className="!w-[240px] h-[56px] text-base bg-contain"
            onClick={handleRestore}
          >
            {t('modal.premium.restore')}
          </EdgeButton>
        </div>
      </div>
    </ModalLayout>
  );
}
